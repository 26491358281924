import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import FramelessLayout from '../components/layouts/FramelessLayout'
import Header from '../components/shared/Header'
import Footer from '../components/shared/Footer'

const ImprintPage = props => {
  const { data } = props
  const seo = {
    title: data.wordpressPage.acf.seo_meta_title || "",
    description: data.wordpressPage.acf.seo_meta_description || "",
    robotsIndex: data.wordpressPage.acf.seo_index ? 'index' : 'noindex',
    robotsFollow: data.wordpressPage.acf.seo_follow ? 'follow' : 'nofollow',
  }
  const { content, title } = data.wordpressPage

  return (
    <FramelessLayout>
      <Helmet
        title={seo.title}
        bodyAttributes={{
          class: 'has-grouped-logo has-back-button',
        }}
      >
        <html lang="en"/>
                  { seo.description !== "" &&
          <meta name="description" content={seo.description} />
          }
          <meta name="robots" content={seo.robotsIndex + ", " + seo.robotsFollow} />
        <link rel='canonical' href='https://boldbreed.de/imprint/' />
      </Helmet>

      <div className='wrapper'>
        <div className='wrapper__lines'></div>

        <div className='wrapper__inner'>
          <Header path={props.path} />

          <section className='section-cols static'>
            <h2>{title}</h2>
            <div className='section__entry static'>
              <div
                className='section__col__double'
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </section>

          <Footer />
        </div>
      </div>
    </FramelessLayout>
  )
}

export default ImprintPage

export const contactQuery = graphql`
  query ImprintPageQuery {
    wordpressPage(title: { eq: "Imprint" }) {
      content
      title
      acf {
        seo_meta_title
        seo_meta_description
        seo_index
        seo_follow
      }
    }
  }
`
